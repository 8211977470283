import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/Index.scss';
import classes from './contact.module.scss';

import Navbar from '../components/Navbar/Navbar';
import Hero, { HeroType } from '../components/Hero/Hero';
import { ContactUsCaption, SeoCaption } from '../constants';
import ContactForm from '../components/ContactForm/ContactForm';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { useQueryParam, StringParam } from 'use-query-params';

const Contact: FC<PageProps> = ({ path }) => {
  const [dropdownLink] = useQueryParam('dropdown', StringParam);

  return (
    <Layout>
      <SEO title={SeoCaption.CONTACT} />
      <Hero page={HeroType.CONTACT}>
        <Navbar pathname={path} />
        <div className={classes.TitleContainer}>
          <div className={classes.Title}>{ContactUsCaption.HEADER_TITLE}</div>
        </div>
      </Hero>
      <div className={classes.ContentContainer}>
        <div className={classes.Description}>
          <p>{ContactUsCaption.DESCRIPTION_1}</p>
          <br />
          <p>{ContactUsCaption.DESCRIPTION_2}</p>
        </div>
        <div className={classes.Form}>
          <ContactForm dropdownLink={dropdownLink} />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
